class Main {
    constructor() {
        this.initializeElements();
        this.macyInstance = null;
        this.imageLoadOffset = 0;
        this.imagesPerPage = 20;
        this.loading = false;

        this.debouncedSearch = this.debounce(() => {
            this.imageLoadOffset = 0; // Reset offset for new searches
            this.performSearch();
        }, 300);

        this.populateGallery(true);
        this.addEventListeners();
    }

    performSearch() {
        if (this.loading) return;
        this.loading = true;
        const params = new URLSearchParams({
            action: 'search',
            q: this.searchInput.value,
            offset: this.imageLoadOffset,
            limit: this.imagesPerPage
        });
        fetch(`api/server.php?${params.toString()}`)
            .then(response => response.json())
            .then(data => {
                if (this.imageLoadOffset === 0) this.gallery.innerHTML = ""; // Clear gallery only on new search
                data.forEach(image => this.appendImage(image));
                this.imageLoadOffset += data.length;
                this.loading = false;
                if (this.macyInstance) this.refreshMacyPromise();
            });
    }

    initializeElements() {
        this.searchInput = document.getElementById('searchInput');
        this.gallery = document.getElementById('gallery');
        this.modal = document.getElementById('modal');
        this.modalImage = document.getElementById('modalImage');
        this.modalClose = document.getElementById('modalClose');
        this.captionText = document.getElementById('caption');
    }

    addEventListeners() {
        this.searchInput.addEventListener('input', this.debouncedSearch.bind(this));
        document.addEventListener("DOMContentLoaded", this.onInit.bind(this));
        this.modalClose.addEventListener('click', () => this.modal.style.display = 'none');
        window.addEventListener('click', event => {
            if (event.target == this.modal) {
                this.modal.style.display = 'none';
            }
        });
        window.addEventListener('scroll', this.checkScroll.bind(this));
    }

    onInit() {
        this.macyInstance = Macy({
            container: '#gallery',
            waitForImages: false,
            margin: 16,
            breakAt: {
                1200: 5,
                940: 3,
                520: 2,
                400: 1
            }
        });
    }

    populateGallery(initial) {
        if (initial) {
            this.imageLoadOffset = 0; // Reset offset for initial load
            this.gallery.innerHTML = ""; // Optionally clear gallery for initial load
        }
        this.performSearch(); // Use search functionality to populate gallery initially
    }

    appendImage(image) {
        const imgContainer = document.createElement('div');
        imgContainer.classList.add('image-container');
        imgContainer.setAttribute('data-src', image.path);
        imgContainer.setAttribute('data-prompt', image.prompt);

        let promptText = '';
        const promptMatch = image.prompt.match(/\*Prompt:\*(.*?)\*/);
        if (promptMatch) {
            promptText = promptMatch[1].trim();
        }

        const img = document.createElement('img');
        img.src = image.path;
        img.alt = promptText;

        const promptDiv = document.createElement('div');
        promptDiv.classList.add('prompt');
        promptDiv.textContent = promptText;

        imgContainer.appendChild(img);
        imgContainer.appendChild(promptDiv);

        this.attachModalHandler(imgContainer);

        this.gallery.appendChild(imgContainer);
    }

    checkScroll() {
        const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
        if (scrollTop + clientHeight >= scrollHeight - 150 && !this.loading) {
            this.populateGallery(false); // Use the search functionality to load more images on scroll
        }
    }

    attachModalHandler(container) {
        container.addEventListener('click', () => {
            const src = container.getAttribute('data-src');
            const prompt = container.getAttribute('data-prompt');
            this.modalImage.src = src;
            this.captionText.innerHTML = this.formatPrompt(prompt);
            this.modal.style.display = 'flex';

            // Add click event listeners to search values
            document.querySelectorAll('.prompt-value').forEach(element => {
                element.addEventListener('click', (e) => {
                    this.modal.style.display = 'none'; // Close modal
                    const searchTerm = e.target.getAttribute('data-search');
                    this.searchInput.value = searchTerm; // Set search input value
                    this.imageLoadOffset = 0; // Reset image load offset for new search
                    this.performSearch(searchTerm); // Perform search with clicked value
                });
            });
        });
    }

    formatPrompt(prompt) {
        let parts = prompt.split('*');
        let formattedPrompt = '<div class="prompt-container">';
    
        // The first part, before the first asterisk, includes the username
        if (parts.length > 0) {
            let username = parts[0].trim();
            formattedPrompt += `<div class="prompt-username prompt-value" data-search="${username}">${username}</div>`;
        }

        // Iterate over the remaining parts, where odd indexes are keys and even indexes are values
        for (let i = 1; i < parts.length; i += 2) {
            let key = parts[i].trim();
            let value = parts[i + 1]; // Split by colon to get the value
            let fullSearchTerm = `${key}*${value}`;
            // Only add to formattedPrompt if both key and value exist
            if (key && value) {
                formattedPrompt += `<div class="prompt-row"><span class="prompt-key">${key}</span> <span class="prompt-value" data-search="${fullSearchTerm}">${value}</span></div>`;
            }
        }
    
        formattedPrompt += '</div>';
        return formattedPrompt;
    }
    
    
    
    
    observeImage(img) {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const image = entry.target;
                    image.src = image.dataset.src;
                    observer.unobserve(image);
                }
            });
        });
        observer.observe(img);
    }

    debounce(fn, delay) {
        let timeoutID = null;
        return (...args) => {
            clearTimeout(timeoutID);
            timeoutID = setTimeout(() => fn.apply(this, args), delay);
        };
    }

    refreshMacyPromise(){
        const loadingImages = Array.from(document.images);
        const imageLoadPromises = loadingImages.map(img => {
            if (img.complete) return Promise.resolve();
            return new Promise((resolve, reject) => {
                img.addEventListener('load', resolve);
                img.addEventListener('error', reject);
            });
        });

        this.refreshMacy();

        Promise.all(imageLoadPromises)
        .then(() => {
            // console.log('All images are loaded');
            this.refreshMacy();
            document.getElementById("gallery").classList.add("loaded");
            // Your code here
        })
        .catch(err => {
            console.log('Some images failed to load', err);
        });
    }

    refreshMacy(){
        var isLoaded = this.macyInstance.recalculate(true, true);
    }
}

const main = new Main();
